<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            {{ chartTitle }}
            <help-btn :new-version="true"></help-btn>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="toggle_tvr_share"
              mandatory
              dense
              data-html2canvas-ignore
            >
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Рейтинг
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Доля
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card-text>
            <v-col>
              <div id="highchartDiv">
                <p data-html2canvas-ignore>
                  Онлайн отчет показывает предварительные данные за последние 24
                  часа по показателям Доля (Share) и Рейтинг (TVR) наиболее
                  популярных телеканалов. Основой для телеизмерений являются RPD
                  данные более 250 операторов связи. Статистика о переключениях
                  телеканалов собирается на серверах и обрабатывается в
                  соответствии с Методологией MediaHills. Данные в отчете
                  отображаются с задержкой всего в несколько минуту от текущего
                  времени. По мере поступления дополнительной статистики от
                  операторов связи значения аудиторных показателей могут
                  обновляться. Окончательные результаты доступны в базовой
                  отчетности MediaHills.
                </p>
                <v-row class="mb-2">
                  <v-col
                    class="col-12 col-md-8 text-md-left ml-0 pl-0 pb-0"
                    style="word-break: break-word"
                  >
                    <p class="chartTitle">{{ subtitle }}</p>
                  </v-col>
                  <v-col
                    class="col-12 col-md-4 text-center text-md-right"
                    id="chartControls"
                    data-html2canvas-ignore
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          elevation="0"
                          class="mh-secondary-border ml-2"
                          style="text-transform: none !important"
                          v-bind="attrs"
                          v-on="on"
                          data-html2canvas-ignore
                        >
                          <v-icon small>mdi-download</v-icon>Скачать</v-btn
                        >
                      </template>
                      <v-list dense>
                        <v-list-item v-if="false" dense link @click="toExcel">
                          <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                        </v-list-item>
                        <v-list-item dense link @click="saveChartToPng">
                          <v-list-item-title
                            >Изображение (png)</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>

                <highcharts ref="top20Chart" :options="top20Chart"></highcharts>
                <span class="chartInfoMessage" data-html2canvas-ignore>
                  <v-icon small color="#FFA91B" class="pr-1"
                    >mdi-lightbulb</v-icon
                  >
                  Нажмите на столбик телеканала, чтобы посмотреть детализацию
                </span>
                <v-divider class="chartDivider" id="hminChart" />
                <p class="chartTitle">Поминутная динамика ({{ ind }})</p>
                <p class="chartSubtitle">
                  <span
                    v-if="hasVideoChannel"
                    style="
                      font-size: 1.1em;
                      font-family: 'Material Design Icons';
                    "
                    >&#xF0567;</span
                  >
                  <span
                    v-if="!hasVideoChannel"
                    style="
                      font-size: 1.1em;
                      font-family: 'Material Design Icons';
                    "
                    >&#xF0568;</span
                  >
                  {{ selectedTvName }} {{ dtStart }} - {{ dtFinish }}
                </p>
                <highcharts
                  ref="minuteChart"
                  :options="minuteChart"
                ></highcharts>
                <span class="chartInfoMessage" data-html2canvas-ignore>
                  <v-icon small color="#FFA91B" class="pr-1"
                    >mdi-lightbulb</v-icon
                  >Выделите период на графике, чтобы посмотреть детализацию
                </span>
                <v-divider class="chartDivider" />
                <p class="chartTitle">
                  Приток-отток телезрителей в поминутном формате
                </p>
                <p class="chartSubtitle">
                  <span
                    v-if="hasVideoChannel"
                    style="
                      font-size: 1.1em;
                      font-family: 'Material Design Icons';
                    "
                    >&#xF0567;</span
                  >
                  <span
                    v-if="!hasVideoChannel"
                    style="
                      font-size: 1.1em;
                      font-family: 'Material Design Icons';
                    "
                    >&#xF0568;</span
                  >
                  {{ selectedTvName }} {{ dtStart }} - {{ dtFinish }}
                </p>
                <highcharts ref="ioChart" :options="minuteIOChart"></highcharts>
                <span class="chartInfoMessage" data-html2canvas-ignore>
                  <v-icon small color="#FFA91B" class="pr-1"
                    >mdi-lightbulb</v-icon
                  >Выделите период на графике, чтобы посмотреть детализацию
                </span>
                <v-divider class="chartDivider" />
                <p class="chartTitle">{{ ind }} телепередач</p>
                <p class="chartSubtitle">
                  {{ selectedTvName }} {{ dtStart }} - {{ dtFinish }}
                </p>
                <highcharts
                  ref="progChart"
                  :options="progAllChart"
                ></highcharts>
              </div>
              <v-btn v-if="false" @click="toExcel()">Xlsx</v-btn>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart"
          v-else-if="chart_loading === true"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            {{ chartTitle }}
            <help-btn :new-version="true"></help-btn> <v-spacer></v-spacer>
          </v-card-title>
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <video-player
      :shown="videoPlayerOn"
      :dt="videoPlayerDt"
      :videoChannelId="videoPlayerChannel"
      :videoChannelName="videoPlayerChannelName"
      :valueName="videoPlayerValueName"
      :values="videoPlayerValues"
      :program="videoPlayerProgram"
      @close="videoPlayerOn = false"
    />
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=OnlineReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
  </v-container>
</template>
<style lang="scss" scoped>
.pageTitle {
  font-weight: bold;
  color: black;
}
</style>
<script>
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import html2canvas from "html2canvas";
import retypeLangRu from "../services/retypeLangRu";
import retypeLangEn from "../services/retypeLangEn";
import HelpBtn from "@/components/HelpBtn.vue";
import uuidv4 from "uuid/v4";
import BaseLoader from "@/components/BaseLoader.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import PreReportMount from "@/components/PreReportMount.vue";
import { CONSTS } from "../services/constants.js";
import Utils from "../services/utils";

export default {
  name: "OnlineReport",
  components: {
    BaseLoader,
    HelpBtn,
    highcharts: Chart,
    VideoPlayer,
    PreReportMount,
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    prepareValuesData(categories, series, series2 = null) {
      const values = {};
      for (let i = 0; i < categories.length; i++) {
        const key = moment(categories[i]).utc().format("YYYY-MM-DD HH:mm");
        const newValues = [series.data[i].y];
        if (series2) {
          // приходы-уходы - 2 значения
          newValues.push(series2.data[i].y);
        }
        values[key] = newValues;
      }
      return values;
    },
    playPoint(videoChannelId, dt, channelName, valueName, programs, values) {
      console.log(
        `Play: ${videoChannelId} (${channelName}) time: ${dt} ${this.videoPlayerOn}`
      );
      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
          this.playPoint(
            videoChannelId,
            dt,
            channelName,
            valueName,
            programs,
            values
          )
        );
      }
      this.videoPlayerChannel = videoChannelId;
      this.videoPlayerChannelName = channelName;
      this.videoPlayerValueName = valueName;
      this.videoPlayerDt = dt;
      this.videoPlayerProgram = programs;
      this.videoPlayerValues = values;
      this.videoPlayerOn = true;
    },
    handleResize() {
      this.contentWidth = document.getElementById("highchartDiv").clientWidth;
      this.contentWidth -= 70;
    },
    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },
    filterAutoComplete(item, queryText, itemText) {
      queryText = queryText.toLocaleLowerCase();
      let queryTextRu = retypeLangRu(queryText);
      let queryTextEn = retypeLangEn(queryText);
      itemText = itemText.toLocaleLowerCase();
      return (
        itemText.indexOf(queryTextEn) > -1 || itemText.indexOf(queryTextRu) > -1
      );
    },
    makeFixed(val, digits, expandable = false) {
      return Utils.makeFixed(val, digits, expandable);
    },
    saveChartToPng() {
      html2canvas(document.querySelector(`#highcharts_png`)).then(function (
        canvas
      ) {
        saveAs(canvas.toDataURL(), `chart.png`);
      });
      function saveAs(uri, filename) {
        var link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
      }
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      alert("Данные скопированы");
    },
    async getData() {
      this.selectedTVId = null;
      this.reportUuid = uuidv4();
      const req = new FormData();
      req.set("uuid", this.reportUuid);
      req.set("geo", this.selectedCityId);

      let res = await axios.post("/reports/top20", req);

      this.tvs = res.data.resultTvList;
      this.selectedTVId = this.tvs[CONSTS.TOP20_PREOPEN_CHANNEL_INDEX].id; // сначала строим второй загруженный канал, дублируем индекс в data()
      this.ioChart = res.data.resultIncomesOutcomes;
      //console.log(this.ioChart)
      this.aggregatedDataIO = this.aggregateData(this.ioChart);
      this.tvrChart = res.data.resultTVR;
      console.log(this.tvrChart)
      this.progChart = res.data.resultProgs;
      this.shareChart = res.data.resultShares;
      this.topChart = res.data.resultTop20;
     //console.log(this.topChart.charts[0].series[0]);
      this.topChart.charts[0].series[0].data.map(item=> {item.tvr = +item.tvr.toFixed(3), item.share = +item.share.toFixed(3) })
      this.minuteShift =  Object.values(this.tvrChart)[0].charts[0].categories[1439].slice(-2);
      this.chart_loading = false;
      setTimeout(() => this.handleResize());
    },
    aggregateData(sourceData) {
      const targetData = {};
      for (const tvId in sourceData) {
        targetData[tvId] = { charts: [] };
        for (const chart of sourceData[tvId].charts) {
          // console.log(chart)
          const newCategories = [
            ...chart.categories.filter(
              (item, i) => i % this.aggregateStep == 0
            ),
          ];
          const newSeries = [];
          for (let seriesId = 0; seriesId < chart.series.length; seriesId++) {
            newSeries[seriesId] = {
              name: chart.series[seriesId].name,
              subtitle: chart.series[seriesId].subtitle,
              tvid: chart.series[seriesId].tvid,
              video_channel_id: chart.series[seriesId].video_channel_id,
            };
            const newData = [];
            let c = 0,
              tot = 0;
            for (let i = 0; i < chart.series[seriesId].data.length; i++) {
              if (c < this.aggregateStep)
                tot += chart.series[seriesId].data[i].y;
              //console.log(c, tot, chart.series[seriesId].data[i].y)
              c += 1;
              if (c == this.aggregateStep) {
                newData.push({ y: +(tot / this.aggregateStep).toFixed(2) });
                tot = 0;
                c = 0;
                //newSeries[seriesId].data.push(tot / this.aggregateStep);
              }
            }
            newSeries[seriesId].data = newData;
            //series.data = series.data.filter( (item, i)=>i%this.aggregateStep==0);
          }
          const chartData = {
            categories: newCategories,
            series: newSeries,
          };
          targetData[tvId].charts.push(chartData);
        }
      }
      //console.log(sourceData); console.log(targetData);
      return targetData;
    },
  },
  data: () => ({
    minuteShift: 0, //Сдвиг отметок для графика, в минутах
    aggregateStep: 15,
    contentWidth: null,
    channelIndex: CONSTS.TOP20_PREOPEN_CHANNEL_INDEX, // Выбранный канал на первом графике
    videoPlayerOn: false, // если true - открыт диалог проигрывания видео
    videoPlayerChannel: null,
    videoPlayerChannelName: null,
    videoPlayerValueName: null, // название отображаемого показтеля
    videoPlayerDt: null,
    videoPlayerProgram: null, // расписание передач
    videoPlayerValues: null, // помнутные значения выбранного показателя
    dtStart: null,
    dtFinish: null,
    selectedTVId: null,
    tab: null,
    progAccentShow: true,
    tvForAccent: [],
    toggle_tvr_share: 0,
    selectedCityId: 178,
    range: {},
    report: null,
    isMulti: false,
    detalisation: 1,
    aggregate: null,
    multiCount: 1,
    visionType: "graph",
    isStoped: false,
    allProgramms: [],
    data: {},
    manytv: false,
    indicator: "TVR",
    chart_loading: true,
    tvs: [],
    ioChart: {},
    aggregatedDataIO: {},
    tvrChart: {},
    progChart: {},
    shareChart: {},
    topChart: {},
    pageData: {
      title: "Онлайн",
      type: "online",
      many_channel: true,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    selectedTvName() {
      return this.tvs.find((el) => {
        return el.id == this.selectedTVId;
      })[this.isAdmin ? "ns1_name_admin" : "ns1_name_ru"];
    },
    hasVideoChannel() {
      const selectedTv = this.tvs.find((el) => {
        return el.id == this.selectedTVId;
      });

      return selectedTv && selectedTv.video_channel_id;
    },
    cities() {
      return [
        {
          id: 178,
          name: this.isAdmin ? "[178] Москва" : "Москва",
          name_en: "Moscow",
          name_ru: "Москва",
        },
      ];
    },
    top20Chart() {
      const that = this;

      const channelsNum = this.topChart.charts[0].series[0].data.length;

      let series = [
        {
          pointWidth: 30,
          data: this.topChart.charts[0].series[0].data.map((n) => ({
            y: this.indicator === "TVR" ? n.tvr : n.share,
          })),
          name: this.indicator === "TVR" ? "Рейтинг" : "Доля",
        },
      ];

      const chartMax = Math.max(...series[0].data.map((s) => s.y));
      let bgWidth = (this.contentWidth / channelsNum) | 0;
      let pointWidth = (bgWidth / 1.6) | 0;
      if (pointWidth > 30) pointWidth = 30;
      series[0].pointWidth = pointWidth;

      const bgSeries = new Array(channelsNum)
        .fill()
        .map(() => ({ y: chartMax * 2, color: "rgba(0,0,0,0)" }));
      bgSeries[that.channelIndex].color = CONSTS.TOP20_OPEN_CHANNEL_BG_COLOR;
      series.unshift({
        pointWidth: bgWidth,
        enableMouseTracking: false,
        showInLegend: false,
        grouping: false,
        data: bgSeries,
      });

      //console.log(`Content: ${this.contentWidth} Point: ${pointWidth} bg: ${bgWidth}`);

      let colors = new Array(channelsNum).fill(CONSTS.TOP20_CHANNELS_COLORS);
      colors[this.channelIndex] = CONSTS.TOP20_OPEN_CHANNEL_COLOR;

      return {
        id: "top20",
        chart: {
          type: "column",
          events: {},
          height: this.heightChart,
        },
        title: { text: "" },
        colors,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories: this.topChart.charts[0].categories,
          labels: {
            formatter: function () {
              let str = this.value;
              if (that.channelIndex == this.pos)
                str = `<b style="font-size: 12px">${str}</b>`;
              return str;
            },
            rotation: -60,
          },
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: this.ind },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          column: {
            colorByPoint: true,
          },
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            point: {
              events: {
                click: function () {
                  that.channelIndex = this.index;
                  that.selectedTVId = that.tvs[this.index].id;
                  window.scrollTo(0, top);
                  document
                    .getElementById("hminChart")
                    .scrollIntoView({ behavior: "smooth" });
                },
              },
            },
            stacking: false,
            dataLabels: { enabled: false },
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
          },
        },
        series: series,
      };
    },
    progAllChart() {
      const that = this;
      const series = [
        {
          data: this.progChart[this.selectedTVId].charts[0].series[0].data.map(
            (n) => ({
              y: this.indicator === "TVR" ? n.tvr : n.share,
            })
          ),
          name: this.indicator === "TVR" ? "Рейтинг" : "Доля",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, CONSTS.CHART_BAR_COLORS[1]],
              [1, CONSTS.CHART_BAR_COLORS[0]],
            ],
          },
        },
      ];
      let categories = this.progChart[
        this.selectedTVId
      ].charts[0].categories.map((item) => item.replace(" |  | ", " | "));
      return {
        id: "top20",
        chart: {
          marginLeft: 50,
          type: "bar",
          events: {},
          height: series[0].data.length * (30 + 18) + 120,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: -46,
            y: 4,
            style: {
              color: "#000",
              fontSize: "14px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "30px",
            },
            formatter: function () {
              const data = this.value.split("|");
              const dt = (data.length == 2 ? data[1] : data[2]).trim();
              return `${dt.substring(11, 16)}&nbsp;&nbsp;${data[0]}`;
            },
            events: {
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          opposite: true,
          reversedStacks: false,
          title: { text: this.ind },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
              "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
              "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                      "DD.MM.YYYY HH:mm"
                    )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              ${that.ind}: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${that.selectedTvName}</b><br>
              <b>${title}</b><br>
              ${subtitle}
            `;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 30,
          },
        },
        series: series,
      };
    },
    minuteChart() {
      const that = this;
      const charts = this.indicator === "TVR" ? this.tvrChart : this.shareChart;
      const chart = charts[this.selectedTVId];
      let series = chart.charts[0].series;
      const chartMax = Math.max(...series[0].data.map((s) => s.y));
      that.dtStart = chart.charts[0].categories[0];
      that.dtFinish = chart.charts[0].categories[chart.charts[0].categories.length - 1];

      let plotBands = [];
      // let chartDate = chart.charts[0].series[0].subtitle;

      let ticks = 24,
        mult = 60;
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult - this.minuteShift );
      console.log(tickPositions)

      const categories = chart.charts[0].categories.map(
        (item) => moment(item + ":00Z", "DD.MM.YYYY HH:mm:ssZ").unix() * 1000
      );

      for (let i = 0; i < chart.charts[0].programs.length; i++) {
        plotBands.push({
          from: moment(
            chart.charts[0].programs[i]["start_loc"] + ":00Z",
            "YYYY-MM-DD HH:mm:ssZ"
          ).diff(moment(categories[0]), "minutes"),
          to: moment(
            chart.charts[0].programs[i]["stop_loc"] + ":00Z",
            "YYYY-MM-DD HH:mm:ssZ"
          ).diff(moment(categories[0]), "minutes"),
          id: chart.charts[0].programs[i].id,
          color: i % 2 ? CONSTS.BAND_ACCENT_COLOR : "#FFFFFF",
        });
      }
      return {
        id: "minuteChart",
        chart: {
          type: "areaspline",
          events: {
            click: function (e) {
              //console.log("chart")
              if (e.srcElement.firstChild) return; // reset zoom pressed
              const currentSeries = 0;
              const videoChannelId = series[currentSeries].video_channel_id;
              const channelName = series[currentSeries].name;
              const programs = chart.charts[0].programs;
              const point = parseInt(e.xAxis[0].value);
              const values = that.prepareValuesData(
                categories,
                series[currentSeries]
              );
              const dt = moment(categories[point])
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
              that.playPoint(
                videoChannelId,
                dt,
                channelName,
                that.ind,
                programs,
                values
              );
            },
          },
          zoomType: "x",
          height: 450,
          marginTop: 20,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        tooltip: {
          useHTML: true,
          formatter() {
            function fillProgData(dt) {
              const prog = chart.charts[0].programs.find(
                (item) => item.start_loc <= dt && item.stop_loc >= dt
              );
              return prog;
            }

            let hhmm = moment(this.x).utc().format("DD.MM.YYYY HH:mm");
            const dt = moment(this.x).utc().format("YYYY-MM-DD HH:mm:ss");
            const prog = fillProgData(dt);
            if (prog) {
              return `
                ${hhmm}<br>
                ${that.ind}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b> ${this.series.name}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                ${that.ind}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b> ${this.series.name}</b><br>
                `;
          },
        },
        xAxis: {
          tickPositions,
          tickWidth: 1,
          categories: categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function () {
              const value = moment(this.value).utc().format("YYYY-MM-DD HH:mm");
              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${value.substring(11, 13)}</b>
                  <br>
                </div>
                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">
                <span style=""><b style="font-size: 1.1em;">${value.substring(
                  11,
                  13
                )}</b></span>
                <br>
                </div>
              `;
            },
          },
          // type: "datetime",
          plotBands: plotBands,
        },
        yAxis: {
          max: chartMax,
          min: 0,
          reversedStacks: false,
          title: { text: this.ind },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, CONSTS.CHART_COLORS[0]],
                [1, CONSTS.CHART_COLORS[0].replace("1)", "0)")],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {
              click: function (event) {
                //console.log("plot")
                const seriesId = event.point.series.index;
                const videoChannelId =
                  charts[that.selectedTVId].charts[0].series[seriesId]
                    .video_channel_id;
                const channelName =
                  charts[that.selectedTVId].charts[0].series[seriesId].name;
                const programs = charts[that.selectedTVId].charts[0].programs;
                const values = that.prepareValuesData(
                  categories,
                  series[seriesId]
                );
                const dt = moment(event.point.category)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
                that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  that.ind,
                  programs,
                  values
                );
              },
            },
            turboThreshold: 3600 * 24,
            marker: {
              radius: 2,
            },
          },
        },
        series: series,
      };
    },
    minuteIOChart() {
      const that = this;
      const chartHeight = 600;
      const chart = this.aggregatedDataIO[this.selectedTVId];
      const chartOrig = this.ioChart[this.selectedTVId];

      let series = chart.charts[0].series;
      const seriesOrig = chartOrig.charts[0].series;
      let plotBands = [];

      let ticks = 24,
        mult = 60 / this.aggregateStep;
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult - ((this.minuteShift/ this.aggregateStep)|0) );
      console.log(this.aggregateStep,tickPositions)

      const categories = chart.charts[0].categories.map(
        (item) => moment(item + ":00Z", "DD.MM.YYYY HH:mm:ssZ").unix() * 1000
      );

      const categoriesOrig = chartOrig.charts[0].categories.map(
        (item) => moment(item + ":00Z", "DD.MM.YYYY HH:mm:ssZ").unix() * 1000
      );

      //  console.log(seriesOrig, categoriesOrig);

      for (
        let i = 0;
        i < this.tvrChart[this.selectedTVId].charts[0].programs.length;
        i++
      ) {
        plotBands.push({
          from:
            moment(
              this.tvrChart[this.selectedTVId].charts[0].programs[i][
                "start_loc"
              ] + ":00Z",
              "YYYY-MM-DD HH:mm:ssZ"
            ).diff(moment(categories[0]), "minutes") / this.aggregateStep,
          to:
            moment(
              this.tvrChart[this.selectedTVId].charts[0].programs[i][
                "stop_loc"
              ] + ":00Z",
              "YYYY-MM-DD HH:mm:ssZ"
            ).diff(moment(categories[0]), "minutes") / this.aggregateStep,
          id: this.tvrChart[this.selectedTVId].charts[0].programs[i].id,
          color: i % 2 ? CONSTS.BAND_ACCENT_COLOR : "#FFFFFF",
          events: {
            mouseover: function (e) {
              const chart = this.axis.chart;
              let onSeries = 0;
              if (e.offsetY > chartHeight / 2) onSeries = 1;
              chart.series[onSeries].onMouseOver();
            },
          },
        });
      }

      return {
        id: "minuteIoChart",
        chart: {
          type: "column",
          events: {
            click: function (e) {
              if (e.srcElement.firstChild) return; // reset zoom pressed
              const currentSeries = 0;
              const videoChannelId = series[currentSeries].video_channel_id;
              const channelName = that.selectedTvName;
              const point = parseInt(e.xAxis[0].value);
              const programs =
                that.tvrChart[that.selectedTVId].charts[0].programs;
              const values = that.prepareValuesData(
                categoriesOrig,
                seriesOrig[0],
                seriesOrig[1]
              );
              const dt = moment(categories[point])
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
              that.playPoint(
                videoChannelId,
                dt,
                channelName,
                "inout",
                programs,
                values
              );
            },
          },
          zoomType: "x",
          height: chartHeight,
          marginTop: 20,
        },
        title: { text: "" },
        colors: CONSTS.TOP20_CHART_INOUT_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        tooltip: {
          useHTML: true,
          positioner: function (labelWidth, labelHeight, point) {
            const maxWidth = that.contentWidth + 48;
            let tooltipX = point.plotX;
            let tooltipY = point.plotY;
            if (tooltipY + labelHeight > 550) tooltipY = 550 - labelHeight;
            if (tooltipX > maxWidth - labelWidth)
              tooltipX = maxWidth - labelWidth;
            return {
              x: tooltipX,
              y: tooltipY,
            };
          },
          formatter() {
            function fillProgData(dt) {
              const prog = that.tvrChart[
                that.selectedTVId
              ].charts[0].programs.find(
                (item) => item.start_loc <= dt && item.stop_loc >= dt
              );
              return prog;
            }

            let hhmm = moment(this.x).utc().format("DD.MM.YYYY HH:mm");
            const dt = moment(this.x).utc().format("YYYY-MM-DD HH:mm:ss");
            const prog = fillProgData(dt);
            if (prog) {
              return `
                ${hhmm}<br>
                ${this.series.name}, %: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b>${that.selectedTvName}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                ${this.series.name}, %: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b>${that.selectedTvName}</b><br>
                `;
          },
        },
        exporting: {
          sourceWidth: 1200,
          chartOptions: {
            chart: {
              events: {},
            },
          },
        },
        xAxis: {
          tickPositions,
          tickWidth: 1,
          events: {
          },

          categories: categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function () {
              const value = moment(this.value).utc().format("YYYY-MM-DD HH:mm");
              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${value.substring(11, 13)}</b>
                  <br>
                </div>
                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">
                <span style=""><b style="font-size: 1.1em;">${value.substring(
                  11,
                  13
                )}</b></span>
                <br>
                <span style=" display: none;">${value.substring(14, 16)}</span>
                </div>
              `;
            },
          },
          type: "datetime",
          plotBands: plotBands,
        },
        yAxis: {
          reversedStacks: false,
          title: { text: "Проценты" },
          max: 20,
          min: -20,
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          column: {
            grouping: false,
          },
          series: {
            events: {
              click: function (event) {
                //console.log(event)
                const seriesId = 0;
                const videoChannelId = series[seriesId].video_channel_id;
                const channelName = that.selectedTvName;
                const programs =
                  that.tvrChart[that.selectedTVId].charts[0].programs;
                const values = that.prepareValuesData(
                  categoriesOrig,
                  seriesOrig[0],
                  seriesOrig[1]
                );
                const dt = moment(event.srcElement.point.category)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
                that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  "inout",
                  programs,
                  values
                );
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            dataLabels: { enabled: false },
            turboThreshold: 0,
          },
          area: {
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
          },
        },
        series: series,
      };
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    subtitle() {
      let endMoment = Object.values(this.tvrChart)[0].charts[0].categories[1439]
      endMoment = moment(endMoment,"DD.MM.YYYY HH:mm")
/*       let endMoment = moment(
        this.minuteIOChart.xAxis.categories[
          this.minuteIOChart.xAxis.categories.length - 1
        ]
      ).utc(); */
      let end = endMoment.locale("ru").format("DD MMMM YYYY");
      let endTime = endMoment.locale("ru").format("HH:mm");

      return `TOP 20 (${this.ind}) по состоянию на  ${end}, ${endTime}`;
    },
    ind() {
      return this.indicator === "TVR" ? "Рейтинг" : "Доля";
    },
    indicatorsList() {
      return [
        {
          id: "TVR",
          name: "Рейтинг",
          disabled: false,
          visible: true,
          visibleNoAdmin: true,
        },

        {
          id: "Share",
          name: "Доля",
          disabled: false,
          visible: true,
          visibleNoAdmin: true,
        },
      ];
    },
    heightChart() {
      let h = 400;
      return h;
    },
    plotOpts() {
      if (this.report === "hour") {
        return {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {},
            turboThreshold: 3600 * 24,
            marker: {
              radius: 2,
            },
          },
        };
      } else {
        return {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: { enabled: false },
            pointWidth: 20,
          },
        };
      }
    },
    chartTitle() {
      return "Онлайн отчет по городу Москва";
    },
  },
  watch: {
    indicator() {
      if (this.indicator === "All") {
        this.toggle_tvr_share = 1;
      }
    },
    toggle_tvr_share() {
      this.indicator = this.toggle_tvr_share === 0 ? "TVR" : "Share";
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize, true);
    if (process.env.VUE_APP_ALTERNATE_UI != "true")
      // eslint-disable-next-line no-undef
      mh_px(300, {
        ym_uid: this.check_cookie_name("_ym_uid"),
        action: "OnlineReport",
        //email: this.$store.getters.StateUser.email,
        uid: this.$store.getters.StateUser.uid,
      });
    // eslint-disable-next-line no-unused-vars
    let timeReq = performance.now();
    await this.getData();
    timeReq = performance.now() - timeReq;
    if (process.env.VUE_APP_ALTERNATE_UI != "true")
      // eslint-disable-next-line no-undef
      mh_px(300, {
        ym_uid: this.check_cookie_name("_ym_uid"),
        action: "OnlineDataReport",
        uid: this.$store.getters.StateUser.uid,
        msecs: timeReq,
      });
  },
};
</script>
